.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Text-Align {
    text-align:center
}

.Top-Margin-100px {
    margin-top:100px;
}

.Top-Margin {
  margin-top:30px;
}
.TextField {
  margin-top:30px;
  width: 450px;
}
.TextField-medium {
  margin-top:30px;
  width: 75%;
}
.padding-top{
   padding-top: 20px;
}
.padding-top-bottom{
  padding-top: 20px;
  padding-bottom: 20px;
}

.action-buttons{
  float: right;
  padding-top: 20px;
}

.errorMsg{
  color:red;
}
.button-align-top{
  float: right;
  top: -45px;
  right: 5px;
}
.required{
  color:red;
  font-size: 30px;
  margin-top: 10px;
  position: absolute
}

.button-align{
  float: right !important;
  top:-48px !important;
  right: -4px !important;
}

/*banner css*/
.banner {
    position: fixed;
    top: 7%;
    left: 30%;
    width: 40%;
    text-align: center;
    transition: all 0.5s ease-in-out;
    margin-top: 5px;
}

.banner-warning {
    background-color: #FFB81C;
}

.banner-success {
    background-color: green;
}

.banner-error {
    background-color: red;
}

.banner.closed {
    transform: translateY(-100%);
}


